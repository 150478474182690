import "core-js/es6/symbol";
import "core-js/es6/object";
import "core-js/es6/function";
import "core-js/es6/parse-int";
import "core-js/es6/parse-float";
import "core-js/es6/number";
import "core-js/es6/math";
import "core-js/es6/string";
import "core-js/es6/date";
import "core-js/es6/array";
import "core-js/es6/regexp";
import "core-js/es6/map";
import "core-js/es6/weak-map";
import "core-js/es6/set";
import "react-app-polyfill/stable";
import "babel-polyfill";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState } from "react";
import ReactDOM from "react-dom";
//import 'core-js/fn/number/is-nan';

import "core-js/es6/";
import "raf/polyfill";
import { Provider } from "react-translated";
import translation from "./translation/translation";

import "./index.css";

import * as serviceWorker from "./serviceWorker";

import Bowser from "bowser";
import img from "./browserError.jpg";
import "./index.css";
import InstallPWA from "./components/InstallPWA";

import { Alert } from "reactstrap";

const App = React.lazy(() => import("./App"));
console.log("working");
const browser = Bowser.getParser(window.navigator.userAgent);
console.log(browser);
console.log("working");
const message =
  "The current Browser is not supported. Please use latest version of Google Chrome , Microsoft Edge or Mozilla Firefox";
const frenchMessage =
  "Le navigateur actuel n'est pas pris en charge. Veuillez utiliser la dernière version de Google Chrome , Microsoft Edge ou Mozilla Firefox";
const browserName = browser.getBrowserName();
const isValidBrowser = browser.satisfies({
  firefox: ">31",
  opera: ">=22",
  edge: ">=75",
  chrome: ">=70",
  SamsungBrowser: ">=1",
  Safari: ">=1",
});
window.onbeforeunload = function () {
  if (!sessionStorage.getItem("pageRefreshed")) {
    localStorage.removeItem("loginId");
    localStorage.removeItem("password");
  }
  //window.location.assign("/");
  return;
};

console.log("validBrowser", isValidBrowser);
console.log(browserName);
if (isValidBrowser || browserName === "Samsung Internet for Android") {
  ReactDOM.render(
    <React.Suspense fallback={`<div>${browserName}`}>
      <App />
    </React.Suspense>,
    document.getElementById("root")
  );
} else {
  console.log("Hicham: Bad browser");
}
/* if (browserName==='opera') {
                ReactDOM.render(
                    <div style={{display:'flex', alignSelf:'center',alignItems:'center',justifyContent:'center'}}>
                    <img src={img} alt="Browser Error" />
                    </div>,

                    document.getElementById('root'));
            } else if (browserName==='Internet Explorer') {
                ReactDOM.render(
                <div style={{display:'flex', alignSelf:'center',alignItems:'center',justifyContent:'center'}}>
                <img src={img} alt="Browser Error" />
                </div>,

                document.getElementById('root'));
               // Keep your gecko specific URL here.
            } else if (browserName==='Chrome') {
                ReactDOM.render(
                    <React.Suspense fallback={`<div>Loading ....</div>`}>
                    <App />
                    </React.Suspense>,
                document.getElementById('root'));
               // Keep your IE specific URL here.
            }
            else if (browserName==='Edge') {
                ReactDOM.render(
                    <React.Suspense fallback={<div>Loading ....</div>}>
                    <App />
                    </React.Suspense>,
                document.getElementById('root'));
               // Keep your IE specific URL here.
            }
            else if (browserName==='Firefox') {
                ReactDOM.render(
                    <React.Suspense fallback={`<div>Loading ....</div>`}>
                    <App />
                    </React.Suspense>,
                document.getElementById('root'));
               // Keep your IE specific URL here.
            }
            else {

                ReactDOM.render(
                    <React.Suspense fallback={`<div>Loading ....</div>`}>
                    <App />
                    </React.Suspense>,
                document.getElementById('root'));
               // Keep your IE specific URL here.
            }  */

//ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
