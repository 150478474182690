export default {
    'English version': {
        fr: 'English version!',
        en: 'Version anglaise',
    },
    'Bienvenue': {
        fr: 'Bienvenue',
        en: 'Welcome',
    },
    'Entrez vos informations de connexion': {
        fr: 'Entrez vos informations de connexion',
        en: 'Enter your login information',
    },
    'Votre numéro de magasin': {
        fr: 'Votre numéro de magasin',
        en: 'Your store number',
    },
    "Votre nom d'utilisateur": {
        fr: "Votre nom d'utilisateur",
        en: 'Your username',
    },
    "Votre mot de passe": {
        fr: "Votre mot de passe",
        en: 'Your password',
    },
    "Connexion": {
        fr: "Connexion",
        en: 'Login',
    },
    "Se souvenir de moi": {
        fr: "Se souvenir de moi",
        en: 'Remember me',
    },
    "Vos informations de connexion sont invalides!": {
        fr: "Vos informations de connexion sont invalides!",
        en: 'Your login information is invalid!',
    },
    // navbar
    "Accueil": {
        fr: "Accueil",
        en: 'Home',
    },
    "Recherche": {
        fr: "Recherche",
        en: 'Search',
    },
    "Favoris": {
        fr: "Favoris",
        en: 'Favorites',
    },
    "Scan": {
        fr: "Scan",
        en: 'Scan',
    },
    "Commandes": {
        fr: "Commandes",
        en: 'Orders',
    },
    "Plus": {
        fr: "Plus",
        en: 'More',
    },
  }